.badge {
    margin-left: 0.2em;
    cursor: pointer;
    float: right;
}

.badge:first-child:hover {
    background-color: #1FB5B7;
    color: white;
}

.breadcrumb {
    background-color: rgb(243, 245, 247);
    margin-bottom: 0;
}

.breadcrumb .active {
    color: black;
    font-weight: bold;
}

ol:first-child {
    padding-left: 0;
}


#breadcrumb-projects ol {
    display: flex;
    justify-content: center;
    align-items: center;
}

#project-properties .card-body {
    padding: 0.8rem;
}
