/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
    "primary": #e4241c,
    "info": #6569df,
    "info2": #ffe79a
);

.btn-primary {
    color: red;
    background-color: #24d5d8;
  }

$btn-primary-color: red;

.search-box {
    background-color: white;
    padding: 10px 0px 0px;
    justify-content: left;
    text-align: left;
}

.search-text-input {
    width: 100%;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 2px 8px;
}

.propertyContainer {
    background-color: white; 
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0px 20px;
    padding: 5px 16px;
}

.propertyItem { 
    display: flex;
    justify-content: flex-start;
    border: 1px hsl(0,0%,80%) solid;
    border-radius: 4px;
    margin: 5px 15px 5px 0px;
    padding: 5px;
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";