.search-search-bar {
    background-color: white;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.search-keyword-input {
    width: 100%;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 2px 8px;
}
