.photo-button {
    margin: 10px 0 0 10px;
    width: 160px;
}

td {
    vertical-align: middle !important;
}

.min-height-value {
    min-height: 38px;
}

.text-input {
    border-radius: 4px;
    padding: 5px 9px;
    border: 1px solid rgb(204,204,204);
}

.unit {
    background-color: #cccccc;
    padding: 7px 8px 6px;
    border-radius: 0px 4px 4px 0px;
    margin-left: -2px;
}

/* CustomPagination */
.pagination-pages {
    color: black;
    border: 1px solid rgb(204,204,204);
    border-radius: 3px;
}

.pagination-pages a {
    background-color: transparent !important;
    border: none;
    margin: 2px;
}

.pagination-pages:hover{
    color: hsl(181, 71%, 35%);
    background-color: rgba(194, 194, 194, 0.4);
}

.break-class {
    padding-top: 1px;
}

.disabled-item {
    color: transparent !important;
    cursor: default !important;
    border-style: none !important;
    background-color: transparent !important;
}

.disabled-item a {
    background-color: transparent !important;
    border-style: none !important;
    margin: 3px;
    cursor: default;
}

.customPaginationActive {
    color: hsl(181, 71%, 35%);
    background-color: rgba(0, 0, 0, 0.175);
}

.toast-position {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    min-width: 20%;
}

.toast-header-background {
    background-color: hsl(0, 0%, 87%);
}

.toast-icon-style {
    width: 20px;
    height: 20px;
    margin-top: 2px;
}
