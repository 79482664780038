.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn.btn-primary {
  color: white;
}

.react-router-link {
  display: block;
  padding: 0.5rem 0.5rem;
  color: rgba(0, 0, 0, 0.5);
  font-size: 21px;
  text-decoration: none;
}

.react-router-link:hover {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
  text-decoration-color: var(--primary);
  text-underline-position: under;
}

.list-group-item a {
  color: black;
}

.list-group .badge {
  float: none;
  margin-left: 1rem;
}

#all-pages-for-test .badge {
  float: none;
  margin-left: 1rem;
}

#active-project-td .badge {
  float: none;
}

#user-companies-div .badge {
  float: right;
}

.rounded-circle-icon-container {
  width: 43px; 
  height: 43px;
  border-radius: 50%;
  background-color: var(--primary);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.rounded-circle-icon-container:hover {
  background-color: #199496;
  color: black;
  cursor: pointer;
}

.rounded-circle-icon-container a {
  color: white;
}

.link-style {
  font-size: 16px;
  color: var(--primary) !important;
}

.link-style-darker {
  font-size: 16px;
  color: #1daaac !important;
}

.link-style-darker-smaller {
  color: #1daaac !important;
}

/* Accordion */
.each-property-div {
  display: flex;
  justify-content: space-between;
  left: calc(50%-20px);
  margin: 5px 0px;
}

.accordion-style {
  margin-bottom: 5px;
  cursor: pointer;
}

.accordion-toggle-style {
  font-size: 18px;
  color: var(--primary);
  padding: 5px 20px;
}

.card-body-style {
  font-size: 18px;
  padding: 2px 20px;
  cursor: default;
} 