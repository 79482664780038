.row-style {
    background-color: white;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    width: 300px;
    margin-right: -10px;
}

.property-icon {
    height: 25px;
    min-width: 25px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.review-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.item-value {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 10px;
    padding-top: 3px;
    margin-left: 5px;
    width: 20px;
    text-align: center;
}

.price-div {
    background-color: #17a2b8;
    color: white;
    border-radius: 50px;
    width: 95px;
    height: 24px;
}

.rounded-status-div{
    height: 20px;
    width: 20px;
    border-radius: 5px;
    margin-right: 8px;
}

.edit-icon-div {
    background-color: var(--primary); 
    height: 25px; 
    width: 25px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.customized-toast {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10;
}

.linkStyle {
    width: 200px;
    max-height: 200px;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}