.dropdown-item:hover {
    background-color: var(--primary);
}

.link-decoration {
    text-decoration: none;
}

.new-device-div {
    width: 43px; 
    height: 43px;
    border-radius: 50%;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-device-div:hover {
    background-color: #199496;
}

.nav-link:hover {
    text-decoration: underline;
    text-decoration-color: var(--primary);
    text-underline-position: under;
}

.new-device-div a {
    padding: 0;
    margin: 0;
}